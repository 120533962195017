<!--
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:34:47
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 20:48:10
 * @Description:
-->
<template>
  <Tab :list="tabList" :current="1" :showLine="true" />
  <div class="total">
    <span class="count"
      >{{ date }}检测总量：{{ tableConfig.data.length }}批次</span
    >
    <van-button icon="todo-list-o" size="mini" @click="show = true"
      >日期查询</van-button
    >
  </div>

  <van-calendar
    v-model:show="show"
    :min-date="minDate"
    :max-date="maxDate"
    @confirm="onConfirm"
    :show-confirm="false"
    color="#1b51f1"
  />
  <Table class="flex-1" :tableConfig="tableConfig">
    <template v-slot:param3="scope">
      {{ scope.row.param3 }}&nbsp;{{ scope.row.param4 }}
    </template>
    <template v-slot:param7="scope">
      {{ scope.row.param6 }}&nbsp;<span :class="`color-${scope.row.param7}`">{{
        scope.row.param7 === 1 ? '合格' : '不合格'
      }}</span>
    </template>
  </Table>
</template>

<script>
import Tab from '@/components/tab/index.vue'
import Table from '@/components/table/index.vue'
import { reactive, ref } from 'vue'
import { getList, getListByDate } from '@/api/home'
import { homeTabList } from '@/utils/tab'
import { formateDate } from '@/utils/formateDate'

export default {
  components: {
    Tab,
    Table,
  },
  setup() {
    // 表格相关的
    const tableConfig = reactive({
      params: {
        stallName: {
          label: '摊位号',
          align: 'center',
          width: '15%',
        },
        stallUserName: {
          label: '经营户',
          align: 'center',
          width: '15%',
        },
        foodName: {
          label: '样品编号/名称',
          align: 'center',
          width: '30%',
        },
        projectName: {
          label: '检测项目',
          align: 'center',
          width: '15%',
        },
        reportResult: {
          label: '检测结果',
          align: 'center',
          width: '25%',
        },
      },
      data: [],
    })
    getList().then((res) => {
      tableConfig.data = res.result
      tableConfig.data.forEach((item,index)=>{
        if(!item.stallName){
          item.stallName = '流动摊位'
        }
        if(!item.stallUserName){
          item.stallUserName = '-'
        }
      })
    })
    const tabList = reactive(homeTabList)
    const date = ref('')
    const show = ref(false)
    const onConfirm = (value) => {
      date.value = formateDate('yyyy-MM-dd', value)
      show.value = false
      getListByDate(date.value).then((res) => {
        tableConfig.data = res.result
      })
    }

    return {
      tabList,
      tableConfig,
      date,
      show,
      onConfirm,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31),
    }
  },
}
</script>

<style lang="less" scoped>
.color-1 {
  color: #49c5a5;
}
.color-0 {
  color: #f00;
}
.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .count {
    color: #fff;
  }
}
</style>
